import {Fragment, useEffect, useState, useContext} from "react";
import {AuthContext} from "../../contexts/AuthContext";

function PaidInvoicesFilters({type, setType, invoiceDateFrom, setInvoiceDateFrom, invoiceDateTo, setInvoiceDateTo, paymentDateFrom, setPaymentDateFrom, paymentDateTo, setPaymentDateTo, paymentMethod, setPaymentMethod}) {
	const [paymentMethods, setPaymentMethods] = useState([]);

	const {token} = useContext(AuthContext);

	useEffect(() => {
		let isMounted = true;
		(async () => {
			try {
				const jsonPaymentMethods = await fetchPaymentMethods();
				if (isMounted) {
					if (!jsonPaymentMethods.error) {
						setPaymentMethods(jsonPaymentMethods.paymentMethods);
					}
				}
			}
			catch (e) {
				console.error(e);
			}
		})();
		return () => {
			isMounted = false;
		}
	}, []);

	async function fetchPaymentMethods() {
		const response = await fetch(process.env.REACT_APP_endPoint + '/v1/paymentMethods', {
			headers: {
				'Authorization': 'Bearer ' + token
			}
		});
		return response.json();
	}

	return (
		<Fragment>
			<select className={"form-select"} value={type} onChange={(evt) => {
				setType(evt.target.value);
			}}>
				<option value="all">All</option>
				<option value="internal">Internal</option>
				<option value="external">External</option>
			</select>
			<select className={"form-select mt-3"} value={paymentMethod} onChange={(evt) => {
				setPaymentMethod(evt.target.value);
			}}>
				<option value="">Select a payment method</option>
				{paymentMethods.map(value => {
					return <option value={value.id} key={value.id}>{value.name}</option>;
				})}
			</select>
			<div className={"row mt-3"}>
				<b>Filter on invoice date <input type="reset" onClick={(evt) => {
					setInvoiceDateFrom('');
					setInvoiceDateTo('');
				}}/></b> <br/>
				<div className={"col"}>
					<label htmlFor="from">From:</label>
					<input type="date" id={"from"} className={"form-control"} value={invoiceDateFrom}
						   onChange={(evt) => {
							   if (evt.target.value > invoiceDateTo && invoiceDateTo) {
								   evt.preventDefault();
							   } else {
								   setInvoiceDateFrom(evt.target.value);
							   }
						   }}/>
				</div>
				<div className={"col"}>
					<label htmlFor="to">To (invoice date):</label>
					<input type="date" id={"to"} className={"form-control"} value={invoiceDateTo} onChange={(evt) => {
						if (evt.target.value < invoiceDateFrom && invoiceDateFrom) {
							evt.preventDefault();
						} else {
							setInvoiceDateTo(evt.target.value);
						}
					}}/>
				</div>
			</div>
			<div className={"row mt-3"}>
				<b>Filter on payment date <input type="reset" onClick={(evt) => {
					setPaymentDateFrom('');
					setPaymentDateTo('');
				}}/></b> <br/>
				<div className={"col"}>
					<label htmlFor="from">From:</label>
					<input type="date" id={"from"} className={"form-control"} value={paymentDateFrom}
						   onChange={(evt) => {
							   if (evt.target.value > paymentDateTo && paymentDateTo) {
								   evt.preventDefault();
							   } else {
								   setPaymentDateFrom(evt.target.value);
							   }
						   }}/>
				</div>
				<div className={"col"}>
					<label htmlFor="to">To (invoice date):</label>
					<input type="date" id={"to"} className={"form-control"} value={paymentDateTo} onChange={(evt) => {
						if (evt.target.value < paymentDateFrom && paymentDateFrom) {
							evt.preventDefault();
						} else {
							setPaymentDateTo(evt.target.value);
						}
					}}/>
				</div>
			</div>
		</Fragment>
	);
}

export default PaidInvoicesFilters;