import SortableHeader from "../SortableHeader";
import Search from "../Search";
import Pagination from "../Pagination";
import JobFilters from "../JobFilters";
import {Fragment, useContext, useEffect, useState} from "react";
import {SortContext} from "../../contexts/SortContext";
import {NavLink} from "react-router-dom";
import {useParams} from "react-router";
import {AuthContext} from "../../contexts/AuthContext";
import ValueOrSpinner from "../ValueOrSpinner";
import Error from "../Alerts/Error";
import SmallSpinner from "../SmallSpinner";
import Spinner from "../Spinner";
import JobRow from "../JobRow";
import {JobFilterContext} from "../../contexts/JobFilterContext";


function CustomerTask() {
	const [sortBy, setSortBy] = useState("name");
	const [asc, setAsc] = useState(false);
	const [desc, setDesc] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingJobs, setLoadingJobs] = useState(false);
	const [error, setError] = useState(null);
	const [errorJobs, setErrorJobs] = useState(null);
	const [customerTask, setCustomerTask] = useState({});
	const [search, setSearch] = useState("");
	const [languagePair, setLanguagePair] = useState("");
	const [invoiceStatus, setInvoiceStatus] = useState("");
	const [workType, setWorkType] = useState([]);
	const [creationDateFrom, setCreationDateFrom] = useState('');
	const [creationDateTo, setCreationDateTo] = useState('');
	const [invoiceDateFrom, setInvoiceDateFrom] = useState('');
	const [invoiceDateTo, setInvoiceDateTo] = useState('');
	const [page, setPage] = useState(0);
	const [maxPage, setMaxPage] = useState(0);
	const [jobs, setJobs] = useState([]);
	const [quantity, setQuantity] = useState(20);

	const {customerTaskId} = useParams();

	const {token, setToken, setUser} = useContext(AuthContext);
	const {workTypes} = useContext(JobFilterContext);

	useEffect(() => {
		let isMounted = true;

		(async () => {
			setLoading(true);
			setError(null);
			try {
				const jsonCustomerTask = await fetchCustomerTask();
				if (isMounted) {
					if (jsonCustomerTask.error) {
						setError(jsonCustomerTask.error);
					}
					else {
						setCustomerTask(jsonCustomerTask.customerTask);
					}
				}
			}
			catch (e) {
				console.error(e);
				if (isMounted) {
					setError('Failed to reach the server.');
				}
			}
			finally {
				if (isMounted) {
					setLoading(false);
				}
			}
		})();
		return () => {
			isMounted = false;
		}
	}, []);

	useEffect(() => {
		let isMounted = true;

		(async () => {
			setLoadingJobs(true);
			setErrorJobs('');
			try {
				const jsonMaxPage = await fetchMaxPage();
				if (isMounted) {
					if (jsonMaxPage.error) {
						setErrorJobs(jsonMaxPage.error);
					}
					else {
						setMaxPage(jsonMaxPage.maxPage);
						if (page > jsonMaxPage.maxPage) {
							setPage(jsonMaxPage.maxPage);
						}
					}
				}
				const jsonJobs = await fetchJobs();
				if (isMounted) {
					if (jsonJobs.error) {
						setErrorJobs(jsonJobs.error);
					}
					else {
						setJobs(jsonJobs.jobs);
					}
				}
			}
			catch (e) {
				console.error(e);
				if (isMounted) {
					setErrorJobs('Failed to reach the server.');
				}
			}
			finally {
				if (isMounted) {
					setLoadingJobs(false);
				}
			}
		})();


		return () => {
			isMounted = false;
		}
	}, [page, quantity, languagePair, search, workType, creationDateFrom, creationDateTo, invoiceDateFrom, invoiceDateTo, invoiceStatus, sortBy, asc, desc]);

	async function fetchCustomerTask() {
		const response = await fetch(process.env.REACT_APP_endPoint + '/v1/customerTasks/' + customerTaskId, {
			headers: {
				'Authorization': 'Bearer ' + token
			}
		});
		return response.json();
	}

	async function fetchJobs() {
		let params = new URLSearchParams();

		if (search) {
			params.append('search', search);
		}
		if (languagePair) {
			params.append('languagePair', languagePair);
		}
		if (workType) {
			params.append('workType', workType);
		}
		if (creationDateFrom) {
			params.append('creationDateFrom', creationDateFrom);
		}
		if (creationDateTo) {
			params.append('creationDateTo', creationDateTo);
		}
		if (invoiceDateFrom) {
			params.append('invoiceDateFrom', invoiceDateFrom);
		}
		if (invoiceDateTo) {
			params.append('invoiceDateTo', invoiceDateTo);
		}
		if (asc || desc) {
			params.append('asc', asc);
			params.append('desc', desc);
			params.append('sortBy', sortBy);
		}
		if (quantity) {
			params.append('quantity', quantity);
		}
		if (invoiceStatus) {
			params.append('invoiceStatus', invoiceStatus);
		}
		params.append('page', page);
		params.append('customerTaskId', customerTaskId);

		const response = await fetch(process.env.REACT_APP_endPoint + '/v1/jobs?' + params.toString(), {
			headers: {
				'Authorization': 'Bearer ' + token
			}
		});
		return response.json();
	}

	async function fetchMaxPage() {
		let params = new URLSearchParams();

		if (search) {
			params.append('search', search);
		}
		if (languagePair) {
			params.append('languagePair', languagePair);
		}
		if (workType) {
			params.append('workType', workType);
		}
		if (creationDateFrom) {
			params.append('creationDateFrom', creationDateFrom);
		}
		if (creationDateTo) {
			params.append('creationDateTo', creationDateTo);
		}
		if (invoiceDateFrom) {
			params.append('invoiceDateFrom', invoiceDateFrom);
		}
		if (invoiceDateTo) {
			params.append('invoiceDateTo', invoiceDateTo);
		}
		if (asc || desc) {
			params.append('asc', asc);
			params.append('desc', desc);
			params.append('sortBy', sortBy);
		}
		if (quantity) {
			params.append('quantity', quantity);
		}
		if (invoiceStatus) {
			params.append('invoiceStatus', invoiceStatus);
		}
		params.append('customerTaskId', customerTaskId);

		const response = await fetch(process.env.REACT_APP_endPoint + '/v1/jobs/maxPage?' + params.toString(), {
			headers: {
				'Authorization': 'Bearer ' + token
			}
		});
		return response.json();
	}

	return (
		<SortContext.Provider value={{
			sortBy: sortBy,
			asc: asc,
			desc: desc,
			setSortBy: setSortBy,
			setAsc: setAsc,
			setDesc: setDesc
		}}>
			<div className={"m-2 border p-4 bg-white shadow-sm"}>
				{error && <Error message={error} /> }

				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item"><NavLink to={'/customerTasks'}>Customer tasks</NavLink></li>
						<li className="breadcrumb-item active" aria-current="page"><ValueOrSpinner value={customerTask.name} loading={loading}/></li>
					</ol>
				</nav>

				<table className={"table table-sm table-striped m-0 my-4 border shadow-sm"}>
					<tbody>
					<tr>
						<td className={"table-secondary"} style={{width: '15%'}}>Name:</td>
						<td><ValueOrSpinner value={customerTask.name} loading={loading}/></td>
					</tr>
					<tr>
						<td className={"table-secondary"}>Code:</td>
						<td><ValueOrSpinner value={customerTask.code} loading={loading}/></td>
					</tr>
					<tr>
						<td className={"table-secondary"}>Description:</td>
						<td><ValueOrSpinner value={customerTask.description} loading={loading}/></td>
					</tr>
					<tr>
						<td className={"table-secondary"}>PO number:</td>
						<td><ValueOrSpinner value={customerTask.poNumber} loading={loading}/></td>
					</tr>
					<tr>
						<td className={"table-secondary"}>Invoice amount:</td>
						<td>{customerTask.invoiceAmount ? <Fragment>&euro;{customerTask.invoiceAmount.toLocaleString()}</Fragment> : loading ? <SmallSpinner />: 'N.A.'}</td>
					</tr>
					<tr>
						<td className={"table-secondary"}>Invoice date:</td>
						<td><ValueOrSpinner value={customerTask.invoiceDate} loading={loading}/></td>
					</tr>
					<tr>
						<td className={"table-secondary"}>Invoice number:</td>
						<td><ValueOrSpinner value={customerTask.invoiceNumber} loading={loading}/></td>
					</tr>
					<tr>
						<td className={"table-secondary"}>Status:</td>
						<td><ValueOrSpinner value={customerTask.status} loading={loading}/></td>
					</tr>
					</tbody>
					<tbody>
					<tr>
						<td className={"table-secondary"}>Total value jobs paid:</td>
						<td>{loading || customerTask.totals === undefined ? <SmallSpinner /> : <Fragment>&euro;{customerTask.totals.jobsPaid.toLocaleString()}</Fragment>}</td>
					</tr>
					<tr>
						<td className={"table-secondary"}>Total value jobs still to be paid:</td>
						<td>{loading || customerTask.totals === undefined ? <SmallSpinner /> : <Fragment>&euro;{customerTask.totals.jobsNotPaid.toLocaleString()}</Fragment>}</td>
					</tr>
					<tr>
						<td className={"table-secondary"}>Total value jobs delivered and <u>not</u> yet invoiced:</td>
						<td>{loading || customerTask.totals === undefined  ? <SmallSpinner /> : <Fragment>&euro;{customerTask.totals.jobsNotInvoiced.toLocaleString()}</Fragment>}</td>
					</tr>
					<tr>
						<td className={"table-secondary"}>Total value jobs created and <u>not</u> yet delivered:</td>
						<td>{loading || customerTask.totals === undefined  ? <SmallSpinner /> : <Fragment>&euro;{customerTask.totals.jobsNotDelivered.toLocaleString()}</Fragment>}</td>
					</tr>
					</tbody>
				</table>

				<hr />
				{errorJobs && <Error message={errorJobs} /> }

				<Search placeHolder={"Search jobs on name, value, freelancer"} search={search} setSearch={setSearch}/>

				<div className={"mt-4"}>
					<JobFilters languagePair={languagePair} setLanguagePair={setLanguagePair} setWorkType={setWorkType}
								workType={workType} workTypes={workTypes} setInvoiceStatus={setInvoiceStatus} invoiceStatus={invoiceStatus}
								creationDateTo={creationDateTo} setCreationDateTo={setCreationDateTo} creationDateFrom={creationDateFrom} setCreationDateFrom={setCreationDateFrom}
								invoiceDateFrom={invoiceDateFrom} setInvoiceDateFrom={setInvoiceDateFrom} invoiceDateTo={invoiceDateTo} setInvoiceDateTo={setInvoiceDateTo}/>
				</div>
				<div className={"mt-4"}>
					<Pagination page={page} maxPage={maxPage} setPage={setPage} quantity={quantity} setQuantity={setQuantity}/>
				</div>
				<table className={"table table-striped m-0 my-4 border shadow-sm"}>
					<caption className={"caption-top"}>Jobs for '{loading ? <SmallSpinner />: customerTask.name}'</caption>
					<thead className={"table-secondary"}>
					<tr>
						<SortableHeader columnName={"name"} text={"Name"}/>
						<SortableHeader columnName={"workType"} text={"Work type"}/>
						<SortableHeader columnName={"value"} text={"Value"}/>
						<SortableHeader columnName={"freelancer"} text={"Freelancer"}/>
						<th>Description</th>
						<SortableHeader columnName={"invoiceDate"} text={"Invoice date"}/>
						<SortableHeader columnName={"deliveryDate"} text={"Delivery date"}/>
					</tr>
					</thead>
					<tbody>
					{loadingJobs ? <tr><td colSpan={5}>
							<div className="d-flex justify-content-center">
								<Spinner />
							</div>
						</td></tr> :
						jobs.map(value => {
							return <JobRow key={value.id} job={value}/>;
						})
					}
					</tbody>
				</table>
				<Pagination page={page} maxPage={maxPage} setPage={setPage} quantity={quantity} setQuantity={setQuantity}/>
			</div>
		</SortContext.Provider>
	);
}

export default CustomerTask;
